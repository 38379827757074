import { Auth } from "@aws-amplify/auth";
import { isValidEmailFormat, isValidEmailLength } from "@/utils/basicHelpers";

export default {
  data: () => {
    return {
      loading: false,
      emailCached: false,
      email: "",
      code: "",
      error: "",
      validation: {},
      showAllErrors: false,
      response: null,
      step: 1
    };
  },
  computed: {
    touched() {
      return key => {
        return this.validation[key]?.touched == true;
      };
    },
    hasError() {
      return key => {
        return (
          (this.validation[key]?.error == true &&
            (this.showAllErrors == true ||
              this.validation[key]?.touched == true)) ||
          false
        );
      };
    },
    hint() {
      return key => {
        return this.validation[key]?.touched || this.showAllErrors
          ? this.validation[key]?.hint || ""
          : "";
      };
    }
  },
  mounted() {
    this.email = this.$route.params.emailCache;
    if (this.email) {
      this.emailCached = true;
    } else {
      this.email = "";
    }
  },
  methods: {
    changed(key) {
      if (!this.validation[key]) {
        this.validation[key] = {
          touched: true
        };
      } else {
        if (this.validation[key].touched == null) {
          this.validation[key].touched = true;
        } else {
          this.validation[key] = { ...this.validation[key], touched: true };
        }
      }
      this.showAllErrors = true;
      this.validateForm();
    },
    addError(key, message) {
      if (this.validation[key]) {
        this.validation[key].error = true;
        this.validation[key].hint = message;
      } else {
        this.validation[key] = {
          hint: message,
          error: true
        };
      }
    },
    clearError(key) {
      if (this.validation[key]) {
        this.validation[key].error = false;
        this.validation[key].hint = "";
      }
    },
    resetForm() {
      this.error = "";
      this.email = "";
      this.code = "";
      this.response = "";
      this.validation = {};
    },
    validateForm() {
      let valid = true;
      if (!this.emailCached) {
        if (this.email == "") {
          this.addError("email", "The email can't be empty");
          valid = false;
        } else if (!isValidEmailLength(this.email)) {
          this.addError("email", "Email is too long");
          valid = false;
        } else if (!isValidEmailFormat(this.email)) {
          this.addError("email", "Invalid email format");
          valid = false;
        } else {
          this.clearError("email");
        }
      }
      const codeRegex = /^[0-9]{6}$/;
      if (this.code == "") {
        this.addError("code", "The code can't be empty");
        valid = false;
      } else {
        if (codeRegex.test(String(this.code))) {
          this.clearError("code");
        } else {
          this.addError("code", "Invalid code format");
          valid = false;
        }
      }
      return valid;
    },
    async submitVerificationCode() {
      this.response = null;
      this.loading = true;
      this.validateForm() &&
        (await this.confirmSignUp()) &&
        (await this.signInOrRedirect());
      this.loading = false;
    },
    async confirmSignUp() {
      try {
        await Auth.confirmSignUp(this.email.toLowerCase(), this.code);
        return true;
      } catch (err) {
        this.response = this.$t(`message.cognito-${err.code}`, {
          code: err.code,
          message: err.message
        });
      }
      return false;
    },
    async signInOrRedirect() {
      if (this.$route.params.passwordCache) {
        // sign the user in if possible...
        await this.$store.dispatch("auth/login", {
          email: this.email,
          password: this.$route.params.passwordCache
        });
      }
      // but if that fails for whatever reason, their account is still verified
      this.$router.push({ name: "AccountVerified" });
      return true;
    }
  }
};
