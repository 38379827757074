<template>
  <button
    class="block mx-auto text-blue-900 underline capitalize mb-10 mt-10"
    @click="$router.push({ name: 'HelpAndSupport' })"
  >
    {{ $t("message.need-help") }}
  </button>
</template>

<script>
export default {
  name: "NeedHelp"
};
</script>

<style></style>
