<template>
  <div class="mx-auto flex flex-grow flex-col w-full">
    <h2 class="capitalize mx-auto flex justify-center">
      {{ $t("message.enter-your-verification-code") }}
    </h2>
    <div class="or font-body2 mx-auto text-center">
      {{ $t("message.check-your-email-for-verification-code") }}
    </div>
    <div class="flex-grow">
      <form @submit.prevent="submitVerificationCode()">
        <InputWrapper
          class="mt-4"
          name="email"
          :label="$t('message.your-email-address')"
          type="email"
          :error="hasError('email')"
          :touched="touched('email')"
          :placeholder="$t('message.email-address')"
          :hint="hint('email')"
          v-model="email"
          @input="validateForm()"
          @blur="changed('email')"
          v-if="!emailCached"
        />
        <InputWrapper
          class="mt-4"
          name="code"
          :label="$t('message.your-code')"
          type="text"
          :error="hasError('code')"
          :touched="touched('code')"
          :placeholder="$t('message.verification-code')"
          :hint="hint('code')"
          v-model="code"
          @input="validateForm()"
          @blur="changed('code')"
        />
        <div class="mt-8 text-red text-center" v-if="response">
          {{ response }}
        </div>
        <button type="submit" class="mx-auto btn btn-lg btn-default my-4">
          <span class="mx-auto" v-if="!loading">
            {{ $t("message.verify") }}
          </span>
          <div class="loading w-6 h-6" v-else>
            <Spinner class="sm-button" />
          </div>
        </button>
      </form>
      <div class="or font-body2 text-indigo-900 mx-auto text-center capitalize">
        {{ $t("message.or") }}
      </div>
      <button
        @click="$router.push({ name: 'RequestCode' })"
        class="font-button mx-auto text-blue-900 focus:outline-none text-center w-full my-6"
      >
        {{ $t("message.request-a-new-verification-code") }}
      </button>
    </div>
    <NeedHelp class="mt-20" />
  </div>
</template>

<script>
import InputWrapper from "@/components/form/InputWrapper.vue";
import Spinner from "@/components/shared/Spinner.vue";
import NeedHelp from "@/components/register/NeedHelp.vue";
import CodeVerificationMixin from "@/mixins/CodeVerificationMixin.js";

export default {
  name: "RequestCodeVerify",
  mixins: [CodeVerificationMixin],
  components: {
    InputWrapper,
    Spinner,
    NeedHelp
  }
};
</script>

<style></style>
